import './SettingsDialog.css';
import { Fragment, useState, useEffect } from 'react';
import ModalDialog from '../../modalDialog/ModalDialog';
import ModalDialogContent from '../../modalDialog/modalDialogContent/ModalDialogContent';
import ModalDialogButtons from '../../modalDialog/modalDialogButtons/ModalDialogButtons';
import { GetCampaignDefaultSettings, UpdateCampaignDefaultSettings } from '../../../Services';
import { toast } from 'react-toastify';

const SettingsDialog = ({ onClose }) => {
	const [ settingsData, setSettingsData ] = useState({});
	const [ citationsWebsites, setCitationsWebsites ] = useState();
	const [ contentWebsites, setContentWebsites ] = useState();
	const [ categoryName, setCategoryName ] = useState();
	const [ websiteUrl, setWebsiteUrl ] = useState();
	const [ showCategoryPanel, setShowCategoryPanel ] = useState(false);
	const [ showWebsitePanel, setShowWebsitePanel ] = useState(false);
	const [ isProcessingCitations, setIsProcessingCitations ] = useState(true);
	const [ editingCategory, setEditingCategory ] = useState();
	const [ editingWebsite, setEditingWebsite ] = useState();
	const [ selectedCitationsWebsites, setSelectedCitationsWebsites ] = useState([]);
	const [ selectedContentWebsites, setSelectedContentWebsites ] = useState([]);

	useEffect(() => {
		getDefaultSettings();
	}, []);

	const getDefaultSettings = async () => {
		let response = await GetCampaignDefaultSettings();

		if (response && response.data) {
			setSettingsData(response.data);

			let tempCitationsWebsites = [];
			let tempContentWebsites = [];

			if (response.data.industryCategories && response.data.industryCategories.length > 0) {
				response.data.industryCategories.forEach((cat) => {
					if (cat.citationsWebsites && cat.citationsWebsites.length > 0) {
						tempCitationsWebsites.push(...cat.citationsWebsites);
					}

					if (cat.contentWebsites && cat.contentWebsites.length > 0) {
						tempContentWebsites.push(...cat.contentWebsites);
					}
				});
			}

			setCitationsWebsites(tempCitationsWebsites);
			setContentWebsites(tempContentWebsites);
		}
	};

	const handleSettingsChange = async (e) => {
		setSettingsData({
			...settingsData,
			[e.target.name]: e.target.type === 'checkbox' ? e.target.checked : e.target.value
		});
	};

	const handleCloseDialog = () => {
		if (onClose) {
			onClose();
		}
	};

	const handleSaveDefaultSettings = async (data) => {
		let response = await UpdateCampaignDefaultSettings(data);

		if (response) {
			handleCloseDialog();
			toast.success('Default settings updated successfully.');
		} else {
			toast.error(response.message);
		}
	};

	const handleAddNewCategory = () => {
		setShowWebsitePanel(false);
		setShowCategoryPanel(!showCategoryPanel);
	};

	const handleSaveCategory = () => {
		if (!categoryName || categoryName.trim() === '') {
			toast.error('Enter category name');
			return;
		}

		let tempSettingsData = { ...settingsData };

		let catObj = {
			categoryName      : categoryName,
			citationsWebsites : selectedCitationsWebsites,
			contentWebsites   : selectedContentWebsites
		};

		if (editingCategory) {
			let currentCategory = tempSettingsData.industryCategories.find(
				(x) => x.categoryName === editingCategory.categoryName
			);

			if (currentCategory) {
				currentCategory = catObj;
			}
		} else {
			if (!tempSettingsData.industryCategories) {
				tempSettingsData.industryCategories = [];
			}

			tempSettingsData.industryCategories.push(catObj);
		}

		handleSaveDefaultSettings(tempSettingsData);
		setSettingsData(tempSettingsData);
	};

	const handleEditCategory = () => {};

	const handleCancelSaveCategory = () => {
		setIsProcessingCitations(false);
		setShowWebsitePanel(false);
	};

	const handleDeleteCategory = () => {};

	const handleAddNewWebsite = (isCitations = true) => {
		setIsProcessingCitations(isCitations);
		setShowWebsitePanel(!showWebsitePanel);
	};

	const handleSaveWebsite = () => {
		if (!websiteUrl || websiteUrl.trim() === '') {
			toast.error('Enter website url');
			return;
		}

		let tempSettingsData = { ...settingsData };
		let categoryAll;

		//find all websites from All category
		if (tempSettingsData.industryCategories) {
			categoryAll = tempSettingsData.industryCategories.find(
				(x) => x.categoryName === editingCategory.categoryName
			);
		} else {
			categoryAll = {
				categoryName      : 'All',
				citationsWebsites : [],
				contentWebsites   : []
			};
		}

		if (categoryAll) {
			let websiteObj = { websiteUrl: websiteUrl };

			if (isProcessingCitations) {
				if (categoryAll.citationsWebsites && categoryAll.citationsWebsites.includes(websiteObj)) {
					toast.error('Website already exists');
					return;
				}

				categoryAll.citationsWebsites.push(websiteObj);
			} else {
				if (categoryAll.contentWebsites && categoryAll.contentWebsites.includes(websiteObj)) {
					toast.error('Website already exists');
					return;
				}

				categoryAll.contentWebsites.push();
			}

			handleSaveDefaultSettings(tempSettingsData);
			setSettingsData(tempSettingsData);
		}
	};

	const handleEditWebsite = () => {};

	const handleCancelSaveWebsite = () => {
		setIsProcessingCitations(false);
		setShowCategoryPanel(false);
		setShowWebsitePanel(false);
	};

	const handleDeleteWebsite = () => {};

	return (
		<div className="settings-dialog-wrapper">
			<ModalDialog position="right-sidebar">
				<ModalDialogContent align="start">
					{showCategoryPanel ? (
						<Fragment>
							<h4 className="mb-3">Add New Category</h4>

							<div className="row">
								<div className="col-12 mb-3">
									<label htmlFor="categoryName" className="form-label">
										Name:
									</label>
									<input
										type="text"
										className="form-control"
										name="categoryName"
										value={settingsData.categoryName}
										onChange={(e) => setCategoryName(e.target.value)}
									/>
								</div>
								<div className="col-12">
									<ul className="nav nav-tabs" id="tabWebsites" role="tablist">
										<li className="nav-item" role="presentation" key="citationsWebsites">
											<button
												className="nav-link active"
												id="citations-tab"
												data-bs-toggle="tab"
												data-bs-target="#citationsWebsites-tab-pane"
												type="button"
												role="tab"
												aria-controls="citationsWebsites-tab-pane"
												aria-selected="true"
											>
												Citations Websites
											</button>
										</li>
										<li className="nav-item" role="presentation" key="contentWebsites">
											<button
												className="nav-link"
												id="contentWebsites-tab"
												data-bs-toggle="tab"
												data-bs-target="#contentWebsites-tab-pane"
												type="button"
												role="tab"
												aria-controls="contentWebsites-tab-pane"
												aria-selected="true"
											>
												Content Websites
											</button>
										</li>
									</ul>
									<div className="tab-content border mb-3" id="tabWebsitesContent">
										<div
											className="tab-pane fade position-relative p-3 show active"
											id="citationsWebsites-tab-pane"
											role="tabpanel"
											aria-labelledby="citationsWebsites-tab"
											tabIndex="0"
										>
											<div className="new-item-wrapper">
												<button
													className="btn btn-primary"
													onClick={() => handleAddNewWebsite(true)}
												>
													Add New Citations Website
												</button>
											</div>
											<div className="table-wrapper">
												<table className="items-list table table-responsive">
													<tbody>
														{citationsWebsites &&
														citationsWebsites.length > 0 && (
															<Fragment>
																{citationsWebsites.map((website, index) => (
																	<tr key={index}>
																		<td>{website.websiteName}</td>

																		<td className="task-action text-end">
																			<Fragment>
																				<button
																					className="btn btn-link py-0 px-1"
																					onClick={() =>
																						handleEditWebsite(website.id)}
																					title="Edit Website"
																				>
																					<i className="fa-solid fa-pen" />
																				</button>
																				<button
																					className="btn btn-link py-0 px-1"
																					onClick={() =>
																						handleDeleteWebsite(website.id)}
																					title="Delete Website"
																				>
																					<i className="fa-solid fa-trash-can" />
																				</button>
																			</Fragment>
																		</td>
																	</tr>
																))}
															</Fragment>
														)}
													</tbody>
												</table>
											</div>
										</div>
										<div
											className="tab-pane fade position-relative p-3"
											id="contentWebsites-tab-pane"
											role="tabpanel"
											aria-labelledby="contentWebsites-tab"
											tabIndex="0"
										>
											<div className="new-item-wrapper">
												<button
													className="btn btn-primary"
													onClick={() => handleAddNewWebsite(false)}
												>
													Add New Content Website
												</button>
											</div>
											<div className="table-wrapper">
												<table className="items-list table table-responsive">
													<tbody>
														{contentWebsites &&
														contentWebsites.length > 0 && (
															<Fragment>
																{contentWebsites.map((website, index) => (
																	<tr key={index}>
																		<td>{website.websiteName}</td>

																		<td className="task-action text-end">
																			<Fragment>
																				<button
																					className="btn btn-link py-0 px-1"
																					onClick={() =>
																						handleEditWebsite(website.id)}
																					title="Edit Website"
																				>
																					<i className="fa-solid fa-pen" />
																				</button>
																				<button
																					className="btn btn-link py-0 px-1"
																					onClick={() =>
																						handleDeleteWebsite(website.id)}
																					title="Delete Website"
																				>
																					<i className="fa-solid fa-trash-can" />
																				</button>
																			</Fragment>
																		</td>
																	</tr>
																))}
															</Fragment>
														)}
													</tbody>
												</table>
											</div>
										</div>
									</div>
								</div>
							</div>
						</Fragment>
					) : showWebsitePanel ? (
						<Fragment>
							<h4 className="mb-3">{`Add New ${isProcessingCitations
								? 'Citations'
								: 'Content'} Website`}</h4>

							<div className="row">
								<div className="col-12 mb-3">
									<label htmlFor="url" className="form-label">
										Url:
									</label>
									<input
										type="text"
										className="form-control"
										name="url"
										value={websiteUrl}
										onChange={(e) => setWebsiteUrl(e.target.value)}
									/>
								</div>
							</div>
						</Fragment>
					) : (
						<Fragment>
							<h4 className="mb-3">Default Settings</h4>

							<ul className="nav nav-tabs" id="tabSettings" role="tablist">
								<li className="nav-item" role="presentation" key="prompts">
									<button
										className="nav-link active"
										id="prompts-tab"
										data-bs-toggle="tab"
										data-bs-target="#prompts-tab-pane"
										type="button"
										role="tab"
										aria-controls="prompts-tab-pane"
										aria-selected="true"
									>
										Content Prompts
									</button>
								</li>
								{/* <li className="nav-item" role="presentation" key="industryCategories">
									<button
										className="nav-link"
										id="industryCategories-tab"
										data-bs-toggle="tab"
										data-bs-target="#industryCategories-tab-pane"
										type="button"
										role="tab"
										aria-controls="industryCategories-tab-pane"
										aria-selected="true"
									>
										Industry Categories
									</button>
								</li> */}
							</ul>
							<div className="tab-content border mb-3" id="tabSettingsContent">
								<div
									className="tab-pane fade position-relative p-3 show active"
									id="prompts-tab-pane"
									role="tabpanel"
									aria-labelledby="prompts-tab"
									tabIndex="0"
								>
									<div className="row">
										<div className="col-12 mb-3">
											<label htmlFor="headlineInstructionsAI" className="form-label">
												Headline Instructions:
											</label>
											<input
												type="text"
												className="form-control"
												name="headlineInstructionsAI"
												value={settingsData.headlineInstructionsAI || ''}
												onChange={handleSettingsChange}
											/>
										</div>
										<div className="col-12 mb-3">
											<label htmlFor="descriptionInstructionsAI" className="form-label">
												Description Instructions:
											</label>
											<textarea
												className="form-control"
												name="descriptionInstructionsAI"
												value={settingsData.descriptionInstructionsAI || ''}
												onChange={handleSettingsChange}
												rows={3}
											/>
										</div>
										<div className="col-12 mb-3">
											<label htmlFor="keywordsHeadlinePrompt" className="form-label">
												Keywords Headline Prompt:
											</label>
											<textarea
												className="form-control"
												name="keywordsHeadlinePrompt"
												value={settingsData.keywordsHeadlinePrompt || ''}
												onChange={handleSettingsChange}
												rows={3}
											/>
										</div>
										<div className="col-12 mb-3">
											<label htmlFor="keywordsContentPrompt" className="form-label">
												Keywords Content Prompt:
											</label>
											<textarea
												className="form-control"
												name="keywordsContentPrompt"
												value={settingsData.keywordsContentPrompt || ''}
												onChange={handleSettingsChange}
												rows={3}
											/>
										</div>
										<div className="col-12 mb-3">
											<label htmlFor="keywordPostHeadlinePrompt" className="form-label">
												Keywords Post Headline Prompt:
											</label>
											<textarea
												className="form-control"
												name="keywordPostHeadlinePrompt"
												value={settingsData.keywordPostHeadlinePrompt || ''}
												onChange={handleSettingsChange}
												rows={3}
											/>
										</div>
										<div className="col-12 mb-3">
											<label htmlFor="keywordPostContentPrompt" className="form-label">
												Keywords Post Content Prompt:
											</label>
											<textarea
												className="form-control"
												name="keywordPostContentPrompt"
												value={settingsData.keywordPostContentPrompt || ''}
												onChange={handleSettingsChange}
												rows={3}
											/>
										</div>
										<div className="col-12 mb-3">
											<label htmlFor="keywordGroupHeadlinePrompt" className="form-label">
												Keywords Group Headline Prompt:
											</label>
											<textarea
												className="form-control"
												name="keywordGroupHeadlinePrompt"
												value={settingsData.keywordGroupHeadlinePrompt || ''}
												onChange={handleSettingsChange}
												rows={3}
											/>
										</div>
										<div className="col-12 mb-3">
											<label htmlFor="locationContentPrompt" className="form-label">
												Keyword Group Location Prompt:
											</label>
											<textarea
												className="form-control"
												name="locationContentPrompt"
												value={settingsData.locationContentPrompt || ''}
												onChange={handleSettingsChange}
												rows={3}
											/>
										</div>
										<div className="col-12 mb-3">
											<label htmlFor="keywordItemsContentPrompt" className="form-label">
												Keyword Items Content Prompt:
											</label>
											<textarea
												className="form-control"
												name="keywordItemsContentPrompt"
												value={settingsData.keywordItemsContentPrompt || ''}
												onChange={handleSettingsChange}
												rows={3}
											/>
										</div>
									</div>
								</div>
								<div
									className="tab-pane fade position-relative p-3"
									id="industryCategories-tab-pane"
									role="tabpanel"
									aria-labelledby="industryCategories-tab"
									tabIndex="0"
								>
									<div className="new-item-wrapper">
										<button className="btn btn-primary" onClick={handleAddNewCategory}>
											Add New Category
										</button>
									</div>
									<div className="table-wrapper">
										<table className="items-list table border table-responsive">
											<thead>
												<tr>
													<th scope="col">Name</th>
													<th scope="col">Citations Websites</th>
													<th scope="col">Content Websites</th>
													<th scope="col" />
												</tr>
											</thead>
											<tbody>
												{settingsData &&
												settingsData.industryCategories &&
												settingsData.industryCategories.length > 0 && (
													<Fragment>
														{settingsData.industryCategories.map((cat, index) => (
															<tr key={cat.id}>
																<td>{cat.categoryName}</td>
																<td>{cat.citationsWebsites}</td>
																<td>{cat.contentWebsites}</td>

																<td className="task-action text-end">
																	<Fragment>
																		<button
																			className="btn btn-link py-0 px-1"
																			onClick={() => handleEditCategory(cat.id)}
																			title="Edit Category"
																		>
																			<i className="fa-solid fa-pen" />
																		</button>
																		<button
																			className="btn btn-link py-0 px-1"
																			onClick={() => handleDeleteCategory(cat.id)}
																			title="Delete Category"
																		>
																			<i className="fa-solid fa-trash-can" />
																		</button>
																	</Fragment>
																</td>
															</tr>
														))}
													</Fragment>
												)}
											</tbody>
										</table>
									</div>
								</div>
							</div>

							<div className="row">
								<div className="col-12 mb-2">
									<label htmlFor="autoReport" className="form-check-label">
										Auto Report:
									</label>
									<input
										type="checkbox"
										className="form-check-input ms-3"
										name="autoReport"
										checked={settingsData.autoReport}
										onChange={handleSettingsChange}
									/>
								</div>
								<div className="col-12">
									<label htmlFor="autoMergeForward" className="form-check-label">
										Auto Merge Forward 30 Day Report:
									</label>
									<input
										type="checkbox"
										className="form-check-input ms-3"
										name="autoMergeForward"
										checked={settingsData.autoMergeForward}
										onChange={handleSettingsChange}
									/>
								</div>
							</div>
						</Fragment>
					)}
				</ModalDialogContent>
				<ModalDialogButtons>
					<button
						className="btn btn-primary"
						onClick={
							showCategoryPanel ? (
								handleSaveCategory
							) : showWebsitePanel ? (
								handleSaveWebsite
							) : (
								() => handleSaveDefaultSettings(settingsData)
							)
						}
					>
						<span>
							{showCategoryPanel ? 'Save Category' : showWebsitePanel ? 'Save Website' : 'Save Settings'}
						</span>
					</button>
					<button
						className="btn btn-outline-primary"
						onClick={
							showCategoryPanel ? (
								handleCancelSaveCategory
							) : showWebsitePanel ? (
								handleCancelSaveWebsite
							) : (
								handleCloseDialog
							)
						}
					>
						<span>{showCategoryPanel ? 'Cancel' : showWebsitePanel ? 'Cancel' : 'Close'}</span>
					</button>
				</ModalDialogButtons>
			</ModalDialog>
		</div>
	);
};

export default SettingsDialog;
