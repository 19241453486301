import moment from 'moment';

export const campaignDefaultSettings = () => {
	return [
		// { name: 'profile', label: 'Profile', done: false },
		{ name: 'directoryURL', label: 'Domain Setup', done: false },
		{ name: 'setup', label: 'Site Setup', done: false },
		{ name: 'keywords', label: 'Keywords', done: false },
		{ name: 'locations', label: 'Locations', done: false },
		{ name: 'content', label: 'Content', done: false },
		{ name: 'searchConsole', label: 'Search Console', done: false },
		// { name: 'updown.io', label: 'UpDown', done: false },
		{ name: 'report', label: 'Report', done: false }
	];
};

export const copyToClipboard = (text, messageOnSuccess) => {
	navigator.clipboard.writeText(text).then(
		function() {
			if (messageOnSuccess) {
				//toast.success(messageOnSuccess);
			}
		},
		function(err) {
			console.error('Could not copy text: ', err);
		}
	);
};

export const createGuid = (numberOfDigits = null) => {
	let guid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
		const r = (Math.random() * 16) | 0,
			v = c === 'x' ? r : (r & 0x3) | 0x8;
		return v.toString(16);
	});

	return numberOfDigits ? guid.substring(0, numberOfDigits) : guid;
};

export const convertUrlToFile = async (url, filename = '') => {
	console.log('favicon url: ' + url);

	if (!filename) {
		filename = url.split('#').shift().split('?').shift().split('/').pop();
	}

	const res = await fetch(url);
	const blob = await res.blob();

	const mime = blob.type;
	const ext = mime.slice(mime.lastIndexOf('/') + 1, mime.length);
	// Gets blob MIME type (e.g. image/png) and extracts extension

	const file = new File([ blob ], `${filename}.${ext}`, {
		type : mime
	});

	return file;
};

export const imageWebServiceUrl = () => {
	return 'https://fulfillmentws.realwebsite.com';
};

export const replaceMergedFieldsInDefaultSettings = (settings, campaign) => {
	if (settings && campaign) {
		settings.headlineInstructionsAI = settings.headlineInstructionsAI
			.replaceAll('{INDUSTRY}', campaign.listingType)
			.replaceAll('{ENGLISH_TYPE}', campaign.englishType)
			.replaceAll('{CAMPAIGN}', campaign.name);
		settings.descriptionInstructionsAI = settings.descriptionInstructionsAI
			.replaceAll('{INDUSTRY}', campaign.listingType)
			.replaceAll('{ENGLISH_TYPE}', campaign.englishType)
			.replaceAll('{CAMPAIGN}', campaign.name);
		settings.keywordsHeadlinePrompt = settings.keywordsHeadlinePrompt
			.replaceAll('{INDUSTRY}', campaign.listingType)
			.replaceAll('{ENGLISH_TYPE}', campaign.englishType)
			.replaceAll('{CAMPAIGN}', campaign.name);
		settings.keywordsContentPrompt = settings.keywordsContentPrompt
			.replaceAll('{INDUSTRY}', campaign.listingType)
			.replaceAll('{ENGLISH_TYPE}', campaign.englishType)
			.replaceAll('{CAMPAIGN}', campaign.name);

		return settings;
	}
};

export const toTitleCase = (str) => {
	return str.replace(/\w\S*/g, function(txt) {
		return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
	});
};

export const capitalize = (str) => {
	return str && String(str[0]).toUpperCase() + String(str).slice(1);
};

export const slugify = (str) => {
	return str.toLowerCase().trim().replace(/[^\w\s-]/g, '').replace(/[\s_-]+/g, '-').replace(/^-+|-+$/g, '');
};

export const getDuration = (startTime, endTime) => {
	const duration = moment.duration(moment(endTime).diff(startTime));
	const durationAsHours = Math.abs(Math.floor(duration.asHours()));
	const restInMinutes = Math.abs(duration.minutes());
	const hours = durationAsHours !== 0 ? `${durationAsHours}h ` : '';
	const minutes = `${restInMinutes}min`;
	// if less than 1 minute show just the seconds
	//const minutesOrSeconds = milliseconds >= 60000 ? minutes : `${moment.duration(milliseconds).seconds()} seconds`;

	return `${hours}${minutes}`;
};

export const validateDayOfMonth = (dayOfMonthValue) => {
	//get last day of the next month and check its max day
	let today = new Date();
	let lastDateOfMonth = new Date(today.getFullYear(), today.getMonth() + 1 + 1, 0);
	let lastDayOfMonth = lastDateOfMonth.getDate();

	dayOfMonthValue = Number(dayOfMonthValue);

	if (dayOfMonthValue < 1) {
		dayOfMonthValue = 1;
	} else if (dayOfMonthValue > lastDayOfMonth) {
		dayOfMonthValue = lastDayOfMonth;
	}

	return dayOfMonthValue;
};

export const calculateReportDateForNextMonth = (dayOfMonth) => {
	let today = new Date();

	let year = today.getFullYear();
	let nextMonth = (today.getMonth() + 1 + 1).toString().padStart(2, '0');

	return `${year}-${nextMonth}-${dayOfMonth.toString().padStart(2, '0')}`;
};

export const formatCurrency = (value, stripTrailingZeros = false) => {
	value = isNaN(value) ? 0 : value;

	return new Intl.NumberFormat('en-US', {
		style                 : 'currency',
		currency              : 'USD',
		minimumFractionDigits : 2,
		maximumFractionDigits : 2,
		trailingZeroDisplay   : stripTrailingZeros ? 'stripIfInteger' : 'auto'
	}).format(value);
};

export const parseAddressFromGoogleProfile = (addressArgs) => {
	let addressData = {};
	let address, city, state, stateFull, postalCode, country;

	if (addressArgs) {
		let numberArgs = addressArgs.filter((x) => x.types.includes('street_number'));
		let streetArgs = addressArgs.filter((x) => x.types.includes('route'));
		let premiseArgs = addressArgs.filter((x) => x.types.includes('premise'));
		let subpremiseArgs = addressArgs.filter((x) => x.types.includes('subpremise'));
		let cityArgs = addressArgs.filter((x) => x.types.includes('locality'));
		let stateArgs = addressArgs.filter((x) => x.types.includes('administrative_area_level_1'));
		let zipArgs = addressArgs.filter((x) => x.types.includes('postal_code'));
		let countryArgs = addressArgs.filter((x) => x.types.includes('country'));

		let number = numberArgs && numberArgs.length > 0 && numberArgs[0].long_name ? numberArgs[0].long_name : '';
		let street = streetArgs && streetArgs.length > 0 && streetArgs[0].short_name ? streetArgs[0].short_name : '';
		let premise =
			premiseArgs && premiseArgs.length > 0 && premiseArgs[0].long_name ? ` ${premiseArgs[0].long_name}` : '';
		let subpremise =
			subpremiseArgs && subpremiseArgs.length > 0 && subpremiseArgs[0].long_name
				? ` ${subpremiseArgs[0].long_name}`
				: '';
		city = cityArgs && cityArgs.length > 0 && cityArgs[0].long_name ? cityArgs[0].long_name : '';
		state = stateArgs && stateArgs.length > 0 && stateArgs[0].short_name ? stateArgs[0].short_name : '';
		stateFull = stateArgs && stateArgs.length > 0 && stateArgs[0].long_name ? stateArgs[0].long_name : '';
		postalCode = zipArgs && zipArgs.length > 0 && zipArgs[0].short_name ? zipArgs[0].short_name : '';
		country = countryArgs && countryArgs.length > 0 && countryArgs[0].long_name ? countryArgs[0].long_name : '';

		if (number && street) {
			address = `${number} ${street}${premise}${subpremise}`;
		}

		addressData.streetAddress1 = address;
		addressData.city = city;
		addressData.state = state;
		addressData.stateFull = stateFull;
		addressData.postalCode = postalCode;
		addressData.country = country;

		return addressData;
	}
};
