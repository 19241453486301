import { Fragment, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
	DeleteCampaignFiles,
	DownloadDPImage,
	GetCampaign,
	GetCampaignFiles,
	GetCampaignKeywords,
	GetDPImages,
	GetIcons,
	OptimizeImage,
	UpdateCampaign,
	UpdateCampaignImageUrls,
	UpdateCampaignStatus,
	UploadCampaignFiles
} from '../../../Services';
import Spinner from '../../../components/spinner/Spinner';
import './CampaignDesignScreen.css';
import PageHeadline from '../../../components/pageHeadline/PageHeadline';
import { toast } from 'react-toastify';
import CampaignTabs from '../../../components/campaignTabs/CampaignTabs';
import { convertUrlToFile, createGuid, imageWebServiceUrl } from '../../../utils/Common';
import ModalDialog from '../../../components/modalDialog/ModalDialog';
import ModalDialogContent from '../../../components/modalDialog/modalDialogContent/ModalDialogContent';
import ModalDialogButtons from '../../../components/modalDialog/modalDialogButtons/ModalDialogButtons';
import FileUpload from '../../../components/fileUpload/FileUpload';
import CampaignButtonsContainer from '../campaignButtonsContainer/CampaignButtonsContainer';
import { keyboard } from '@testing-library/user-event/dist/keyboard';

const CampaignDesignScreen = () => {
	const [ isLoading, setIsLoading ] = useState(false);
	const [ isSearching, setIsSearching ] = useState(false);
	const [ isSettingPreview, setIsSettingPreview ] = useState(false);
	const [ formData, setFormData ] = useState({});
	const [ campaign, setCampaign ] = useState();
	const { campaignId } = useParams();
	const [ showSelectImageDialog, setShowSelectImageDialog ] = useState(false);
	const [ selectedImageType, setSelectedImageType ] = useState('');
	const [ searchTerm, setSearchTerm ] = useState('');
	const [ allImages, setAllImages ] = useState([]);
	const [ galleryImages, setGalleryImages ] = useState([]);
	const [ imagesList, setImagesList ] = useState();
	const [ selectedImage, setSelectedImage ] = useState({});
	const [ selectedCategory, setSelectedCategory ] = useState();
	const [ iconsList, setIconsList ] = useState([]);
	const [ iconsListPage, setIconsListPage ] = useState(1);
	const imageTypes = [
		{ label: 'Hero', slug: 'Hero' },
		{ label: 'Logo', slug: 'Logo' },
		{ label: 'Favicon', slug: 'Favicon' },
		{ label: 'About Us', slug: 'About' }
	];
	const keywordImageTypes = [ { label: 'Hero', slug: 'Hero' }, { label: 'About Us', slug: 'About' } ];
	const [ keywordImages, setKeywordImages ] = useState([]);
	const [ imageBackgroundSize, setImageBackgroundSize ] = useState('auto');
	const [ keywords, setKeywords ] = useState();
	const [ selectedKeywordImageId, setSelectedKeywordImageId ] = useState();
	const defaultImage = '/img/no-image.png';
	const navigate = useNavigate();

	useEffect(
		() => {
			if (campaignId) {
				getCampaign();
				getCampaignKeywords();
			}
		},
		[ campaignId ]
	);

	useEffect(
		() => {
			if (selectedImageType) {
				if (selectedImageType === 'Favicon') {
					setSelectedCategory('icon');
				} else {
					setSelectedCategory('image');
				}
			}
		},
		[ selectedImageType ]
	);

	useEffect(
		() => {
			if (keywords) {
				getCampaignImages();
			}
		},
		[ keywords ]
	);

	const getCampaign = async () => {
		setIsLoading(true);

		const response = await GetCampaign(campaignId);

		if (response) {
			document.title = response.name;
			setCampaign(response);
			setFormData(response);
		}

		setIsLoading(false);
	};

	const getCampaignImages = async () => {
		const response = await GetCampaignFiles(campaignId);

		if (response) {
			setAllImages(response);

			let tempGalleryImages = response.filter((x) => x.target === 'Gallery');
			let tempKeywordImages = response.filter((x) => x.target.indexOf('|') > -1);

			for (let i = 0; i < tempGalleryImages.length; i++) {
				tempGalleryImages[i].index = i;
			}

			for (let i = 0; i < tempKeywordImages.length; i++) {
				tempKeywordImages[i].index = i;
			}

			if (tempKeywordImages) {
				tempKeywordImages = tempKeywordImages.sort(function(a, b) {
					return new Date(b.dateCreated) - new Date(a.dateCreated);
				});
			}

			setGalleryImages(tempGalleryImages);
			setKeywordImages(tempKeywordImages);
		}
	};

	const getCampaignKeywords = async () => {
		const response = await GetCampaignKeywords(campaignId);

		if (response) {
			let tempKeywords = response;

			tempKeywords = tempKeywords.sort(function(a, b) {
				return a.orderIndex - b.orderIndex;
			});

			for (let i = 0; i < tempKeywords.length; i++) {
				tempKeywords[i].index = i;
				tempKeywords[i].state = '';
			}

			setKeywords(tempKeywords);
		} else {
			setKeywords([]);
		}
	};

	const handleChange = async (e) => {
		setFormData({
			...formData,
			[e.target.name]: e.target.value
		});

		if (e.target.name === 'primaryColorValue') {
			setFormData({
				...formData,
				primaryColor : e.target.value
			});
		} else if (e.target.name === 'accentColorValue') {
			setFormData({
				...formData,
				accentColor : e.target.value
			});
		}
	};

	const handleSubmit = async (redirect = false) => {
		//e.preventDefault();

		let isValid = document.querySelector('form').reportValidity();

		if (!isValid) {
			return;
		}

		const response = await UpdateCampaign(campaignId, formData);

		if (response.success) {
			const responseImages = await UpdateCampaignImageUrls(campaignId, formData);

			if (redirect) {
				navigate(`/setup/${campaignId}/locations`);
			} else {
				toast.success('Settings saved.');
			}
		}
	};

	const selectImage = (e, type, keywordId) => {
		e.preventDefault();

		setSelectedImageType(type);

		if (keywordId) {
			setSelectedKeywordImageId(keywordId);
		} else {
			setSelectedKeywordImageId();
		}

		toggleSelectImageDialog();
	};

	const removeImage = (e, type, keywordId) => {
		e.preventDefault();

		setImagePreview(type, '', keywordId);
	};

	const handleFileUpload = async (files, type, keywordId) => {
		//upload files on server
		if (files !== undefined) {
			var data = new FormData();

			for (var i = 0; i !== files.length; i++) {
				data.append('Files', files[i]);
			}

			data.append('Target', type + (keywordId ? `|${keywordId}` : ''));

			const response = await UploadCampaignFiles(campaignId, data);

			if (response !== undefined && response.data && response.data.length > 0) {
				getCampaignImages();

				setImagePreview(type, response.data[0].fullCdnUrl, keywordId);
			}
		}
	};

	const toggleSelectImageDialog = () => {
		setShowSelectImageDialog(!showSelectImageDialog);
	};

	const onImageSearchKeyDown = (e) => {
		if (e.keyCode === 13) {
			e.preventDefault();

			setSearchTerm(e.target.value);

			if (selectedCategory === 'image') {
				getImages();
			} else {
				getIcons(1);
			}
		}
	};

	const getImages = async () => {
		if (searchTerm && searchTerm !== '') {
			setIsSearching(true);

			//DepositPhoto
			let data = {};
			data.Key = 'd8f3d4af64bd433fa95f33f24469e317';
			data.SearchTerm = searchTerm;
			data.Limit = 200;
			data.Orientation = 'horizontal';

			const response = await GetDPImages(data);

			if (response.IsSuccessful) {
				let tempImagesList = JSON.parse(response.Data);

				setImagesList(tempImagesList);
			} else {
				toast.error('Error: ' + response.Message);
			}

			setIsSearching(false);
		}
	};

	const getIcons = async (pageNumber) => {
		if (searchTerm && searchTerm !== '') {
			let currentIconsList = iconsList;
			setIsSearching(true);

			if (pageNumber === undefined || pageNumber === null || pageNumber <= 1) {
				pageNumber = 1;
				setIconsListPage(1);
				setIconsList([]);
				currentIconsList = [];
			}

			let data = {};
			data.Key = 'e9g3d4af64bd434fa95f43f64469e418';
			data.SearchTerm = searchTerm;
			data.Limit = 50;
			data.Page = pageNumber;
			data.Offset = 0;

			const response = await GetIcons(data);

			if (response.IsSuccessful) {
				if (response.Data === null) {
					setIsSearching(false);
					return;
				}

				let tempIconsList = JSON.parse(response.Data);

				if (currentIconsList) {
					tempIconsList = [ ...currentIconsList, ...tempIconsList ];
				}

				setIconsList(tempIconsList);
			} else {
				toast.error('Error: ' + response.Message);
			}

			setIsSearching(false);
		}
	};

	const viewMoreIcons = () => {
		let tempIconsListPage = iconsListPage;
		tempIconsListPage++;
		setIconsListPage(tempIconsListPage);

		getIcons(tempIconsListPage);
	};

	const selectImageFromList = (e) => {
		let cardSelector = selectedCategory === 'image' ? '.image-card' : '.icon-card';
		Array.from(document.querySelectorAll(cardSelector)).forEach(function(el) {
			el.classList.remove('active');
		});

		e.currentTarget.classList.add('active');

		let imageSelector = selectedCategory === 'image' ? '.image-preview img' : '.icon-image';
		let currentImage = e.currentTarget.querySelector(imageSelector);

		switch (selectedImageType) {
			case 'Favicon':
				setSelectedImage({
					url  : currentImage.src,
					name : selectedImageType.toLowerCase() + `-${createGuid().substring(0, 8)}`
				});
				break;
			default:
				setSelectedImage({
					name     : selectedImageType.toLowerCase() + `-${createGuid().substring(0, 8)}`,
					url      : currentImage.dataset.urlBig,
					thumbMax : currentImage.dataset.thumbMax,
					dpid     : currentImage.dataset.dpid
				});
				break;
		}
	};

	const applySelectedImage = async (e) => {
		e.preventDefault();

		if (selectedImage) {
			setIsSettingPreview(true);

			let imageUrl = '';

			if (selectedCategory === 'image') {
				//purchase image from DepositPhotos and optimize it
				let downloadData = {
					Key : 'd8f3d4af64bd433fa95f33f24469e317',
					Id  : selectedImage.dpid
				};
				const responseDownload = await DownloadDPImage(downloadData);

				if (responseDownload.IsSuccessful) {
					let tempDownloadData = JSON.parse(responseDownload.Data);

					if (tempDownloadData) {
						imageUrl =
							imageWebServiceUrl() +
							'/assets/downloads/depositphotos/original/' +
							selectedImage.dpid +
							'.jpg';

						// //optimize the image
						// let dataOptimization = { ImageName: selectedImage.dpid };
						// const responseOptimization = await OptimizeImage(dataOptimization);

						// if (responseOptimization.IsSuccessful) {
						// 	let optimizedUrl =
						// 		imageWebServiceUrl() +
						// 		'/assets/downloads/depositphotos/optimized/' +
						// 		selectedImage.dpid +
						// 		'.jpg';

						// 	imageUrl = optimizedUrl;
						// }
					}
				}
			} else {
				imageUrl = selectedImage.url;

				console.log();
				console.log(selectedImage);
			}

			//upload file
			var file = await convertUrlToFile(imageUrl, selectedImage.name);

			if (file) {
				var data = new FormData();
				data.append('Files', file);
				data.append('Target', selectedImageType + (selectedKeywordImageId ? `|${selectedKeywordImageId}` : ''));

				const response = await UploadCampaignFiles(campaignId, data);

				if (response !== undefined) {
					setShowSelectImageDialog(false);
					setImagePreview(selectedImageType, response.data[0].fullCdnUrl, selectedKeywordImageId);

					getCampaignImages();
				}
			}

			setIsSettingPreview(false);
			setShowSelectImageDialog(false);
		}
	};

	const getImagePreview = (type) => {
		let imageUrl = defaultImage;

		if (formData) {
			switch (type) {
				case 'Hero':
					imageUrl = formData.heroBackgroundUrl;
					break;
				case 'Logo':
					imageUrl = formData.logoUrl;
					break;
				case 'Favicon':
					imageUrl = formData.faviconUrl;
					break;
				case 'About':
					imageUrl = formData.aboutUsImageUrl;
					break;
				case defaultImage:
					imageUrl = defaultImage;
					break;
			}
		}

		if (imageUrl) {
			imageUrl = imageUrl.replaceAll(' ', '+');
		} else {
			imageUrl = defaultImage;
		}

		return `url(${imageUrl})`;
	};

	const setImagePreview = (type, url, keywordId) => {
		if (keywordId) {
			let tempKeywordImages = [ ...keywordImages ];

			let currentKeywordImage = tempKeywordImages.find((x) => x.target === `${type}|${keywordId}`);

			if (currentKeywordImage) {
				currentKeywordImage.url = url;
			}

			setKeywordImages(tempKeywordImages);
		} else {
			let tempFormData = formData;

			switch (type) {
				case 'Hero':
					tempFormData.heroBackgroundUrl = url;
					break;
				case 'Logo':
					tempFormData.logoUrl = url;
					break;
				case 'Favicon':
					tempFormData.faviconUrl = url;
					break;
				case 'About':
					tempFormData.aboutUsImageUrl = url;
					break;
			}

			setFormData({
				...tempFormData
			});
		}
	};

	const handleChangeImagePreview = (e) => {
		e.preventDefault();

		if (imageBackgroundSize === 'auto') {
			setImageBackgroundSize('cover');
		} else if (imageBackgroundSize === 'cover') {
			setImageBackgroundSize('contain');
		} else {
			setImageBackgroundSize('auto');
		}
	};

	const addNewGalleryImage = (e) => {
		e.preventDefault();

		setGalleryImages([ ...galleryImages, { id: null, name: '', url: defaultImage, index: galleryImages.length } ]);
	};

	const removeGalleryImage = async (e, index) => {
		e.preventDefault();

		let tempGalleryImages = [ ...galleryImages ];

		let imageToRemove = tempGalleryImages.find((x) => x.index === index);

		if (imageToRemove && imageToRemove.id) {
			//delete image from database
			if (window.confirm('Are you sure that you want to delete this image?')) {
				let data = {
					campaignId : campaignId,
					ids        : [ imageToRemove.id ]
				};

				const response = await DeleteCampaignFiles(campaignId, data);

				if (response.success) {
					setGalleryImages(tempGalleryImages.filter((x) => x.index !== index));
				}
			}
		} else {
			setGalleryImages(tempGalleryImages.filter((x) => x.index !== index));
		}
	};

	const removeKeywordImage = async (e, imageSlug, keywordId) => {
		e.preventDefault();

		let tempKeywordImages = [ ...keywordImages ];

		let imageToRemove = tempKeywordImages.find((x) => x.target === `${imageSlug}|${keywordId}`);

		if (imageToRemove && imageToRemove.id) {
			//delete image from database
			if (window.confirm('Are you sure that you want to delete this image?')) {
				let data = {
					campaignId : campaignId,
					ids        : [ imageToRemove.id ]
				};

				const response = await DeleteCampaignFiles(campaignId, data);

				if (response.success) {
					setKeywordImages(tempKeywordImages.filter((x) => x.target !== `${imageSlug}|${keywordId}`));
				}
			}
		} else {
			setKeywordImages(tempKeywordImages.filter((x) => x.target !== `${imageSlug}|${keywordId}`));
		}
	};

	return (
		<div className="container-fluid campaign-main-container">
			<Fragment>
				<PageHeadline
					campaign={campaign}
					headline={`Campaign - ${campaign ? campaign.name : ''}`}
					linkText="Back To List"
					linkUrl="/campaigns/list"
				/>
				<CampaignTabs />

				{isLoading ? (
					<Spinner />
				) : (
					<Fragment>
						<div className="campaign-form-container">
							<ul className="campaign-tabs nav nav-tabs" id="tabDesign" role="tablist">
								<li className="nav-item" role="presentation" key="website">
									<button
										className="nav-link active"
										id="website-tab"
										data-bs-toggle="tab"
										data-bs-target="#website-tab-pane"
										type="button"
										role="tab"
										aria-controls="website-tab-pane"
										aria-selected="true"
									>
										Website
									</button>
								</li>
								<li className="nav-item" role="presentation" key="keywords">
									<button
										className="nav-link"
										id="keywords-tab"
										data-bs-toggle="tab"
										data-bs-target="#keywords-tab-pane"
										type="button"
										role="tab"
										aria-controls="keywords-tab-pane"
										aria-selected="true"
									>
										Keywords
									</button>
								</li>
							</ul>
							<div className="tab-content" id="tabDesignContent">
								<div
									className="tab-pane fade show active"
									id="website-tab-pane"
									role="tabpanel"
									aria-labelledby="website-tab"
									tabIndex="0"
								>
									<div className="campaign-subform-container">
										<form className="text-start">
											<div className="row mb-4">
												<label htmlFor="headerTemplate" className="form-label col-1">
													Header Style:
												</label>
												<div className="col-5">
													<select
														className="form-select"
														name="headerTemplate"
														value={formData.headerTemplate}
														onChange={handleChange}
													>
														<option value="" />
														<option value="Header1">Header 1</option>
														<option value="Header2">Header 2</option>
														<option value="Header3">Header 3</option>
														<option value="Header4">Header 4</option>
														<option value="Header5">Header 5</option>
														<option value="Header5-buttons">Header 5 w/ Buttons</option>
														<option value="Header6">Header 6</option>
														<option value="Header6-buttons">Header 6 w/ Buttons</option>
														{/* <option value="Header7">Header 7</option> */}
													</select>
													<div className="form-controls-additional">
														<a className="btn btn-link p-0" href={`#`} target="_blank">
															Preview
														</a>
													</div>
												</div>
											</div>
											<div className="row mb-4">
												<label htmlFor="primaryColor" className="form-label col-1">
													Colors:
												</label>
												<div className="col-5">
													<div className="d-flex">
														<input
															type="color"
															className="form-control form-control-color"
															name="primaryColor"
															value={formData.primaryColor || ''}
															onChange={handleChange}
														/>
														<input
															type="text"
															className="form-control ms-1 disabled color-output-control"
															name="primaryColorValue"
															value={formData.primaryColor || ''}
															onChange={handleChange}
														/>
														<input
															type="color"
															className="form-control form-control-color ms-5"
															name="accentColor"
															value={formData.accentColor || ''}
															onChange={handleChange}
														/>
														<input
															type="text"
															className="form-control ms-1 disabled color-output-control"
															name="accentColorValue"
															value={formData.accentColor || ''}
															onChange={handleChange}
														/>
													</div>
												</div>
											</div>
											<div className="row mb-3">
												<div className="col-1 align-self-start mt-1">
													<label className="form-label me-2">Images:</label>
													<button
														className="btn btn-link p-0"
														title="Change Preview"
														onClick={handleChangeImagePreview}
													>
														<i className="fa-solid fa-arrow-right-arrow-left" />
													</button>
												</div>
												<div className="col-11">
													<div className="d-flex">
														{imageTypes &&
															imageTypes.map((imageType, index) => {
																let imageUrl = getImagePreview(imageType.slug);

																return (
																	<div className="campaign-image" key={index}>
																		<label className="form-label col-1">
																			{imageType.label}
																		</label>
																		<div
																			className="image-card"
																			style={{
																				backgroundImage : imageUrl,
																				backgroundSize  : imageBackgroundSize
																			}}
																		/>
																		<div className="form-controls-additional">
																			<button
																				className="btn btn-link p-0"
																				onClick={(e) =>
																					selectImage(e, imageType.slug)}
																			>
																				<span>Select</span>
																			</button>
																			<span className="link-separator mx-1">
																				|
																			</span>
																			<FileUpload
																				onFileChange={handleFileUpload}
																				//onClick={handleValidateUpload}
																				isLinkButton={true}
																				imageType={imageType.slug}
																			/>
																			{imageUrl.indexOf(defaultImage) === -1 && (
																				<Fragment>
																					<span className="link-separator mx-1">
																						|
																					</span>
																					<button
																						className="btn btn-link p-0"
																						onClick={(e) =>
																							removeImage(
																								e,
																								imageType.slug
																							)}
																					>
																						<span>Remove</span>
																					</button>
																				</Fragment>
																			)}
																		</div>
																	</div>
																);
															})}
													</div>
												</div>
											</div>
											<div className="row mb-3">
												<div className="col-1 align-self-start mt-1">
													<label className="form-label me-2">Gallery:</label>
												</div>
												<div className="col-11">
													<div className="d-flex">
														{galleryImages && (
															<Fragment>
																{galleryImages.map((galleryImage, index) => {
																	let imageUrl = galleryImage.url || defaultImage;

																	return (
																		<div className="campaign-image" key={index}>
																			<div
																				className="image-card"
																				style={{
																					backgroundImage : `url(${imageUrl})`,
																					backgroundSize  : imageBackgroundSize
																				}}
																			/>
																			<div className="form-controls-additional">
																				<button
																					className="btn btn-link p-0"
																					onClick={(e) =>
																						selectImage(e, 'Gallery')}
																				>
																					<span>Select</span>
																				</button>
																				<span className="link-separator mx-1">
																					|
																				</span>
																				<FileUpload
																					onFileChange={handleFileUpload}
																					isLinkButton={true}
																					imageType="Gallery"
																				/>
																				{/* {imageUrl !== defaultImage && ( */}
																				<Fragment>
																					<span className="link-separator mx-1">
																						|
																					</span>
																					<button
																						className="btn btn-link p-0"
																						onClick={(e) =>
																							removeGalleryImage(
																								e,
																								index
																							)}
																					>
																						<span>Remove</span>
																					</button>
																				</Fragment>
																				{/* )} */}
																			</div>
																		</div>
																	);
																})}
															</Fragment>
														)}
														<button
															className="btn btn-link p-0"
															onClick={(e) => addNewGalleryImage(e)}
														>
															Add Gallery Image
														</button>
													</div>
												</div>
											</div>
											<div className="row mb-4">
												<label className="form-label col-1" />
												<div className="col-11">
													<span className="image-compressor-notice">
														Please use image optimizer to compress images before uploading (<a
															target="_blank"
															href="https://imagecompressor.com"
														>
															https://imagecompressor.com
														</a>)
													</span>
												</div>
											</div>
											<div className="row mb-4">
												<label htmlFor="headScripts" className="form-label col-1">
													Head Scripts:
												</label>
												<div className="col-5">
													<textarea
														className="form-control"
														name="headScripts"
														value={formData.headScripts || ''}
														onChange={handleChange}
														rows={5}
													/>
												</div>
											</div>
										</form>
									</div>
								</div>
								<div
									className="tab-pane fade"
									id="keywords-tab-pane"
									role="tabpanel"
									aria-labelledby="keywords-tab"
									tabIndex="0"
								>
									<div className="campaign-subform-container">
										{keywords &&
										keywords.length &&
										keywordImages && (
											<Fragment>
												{keywords.map((keyword, keywordIndex) => (
													<div className="row mb-3" key={keywordIndex}>
														<div className="col-1 align-self-start mt-1">
															<label className="form-label me-2">{`${keyword.keyword}:`}</label>
															<button
																className="btn btn-link p-0"
																title="Change Preview"
																onClick={handleChangeImagePreview}
															>
																<i className="fa-solid fa-arrow-right-arrow-left" />
															</button>
														</div>
														<div className="col-11">
															<div className="d-flex">
																{keywordImageTypes &&
																	keywordImageTypes.map((keywordImageType, index) => {
																		let imageUrl = defaultImage;

																		if (keywordImages && keywordImages.length) {
																			let currentKeywordImage = keywordImages.find(
																				(x) =>
																					x.target ===
																					`${keywordImageType.slug}|${keyword.id}`
																			);

																			if (currentKeywordImage) {
																				imageUrl = currentKeywordImage.url;
																			}
																		}

																		return (
																			<div className="campaign-image" key={index}>
																				<label className="form-label col-1">
																					{keywordImageType.label}
																				</label>
																				<div
																					className="image-card"
																					style={{
																						backgroundImage : `url(${imageUrl})`,
																						backgroundSize  : imageBackgroundSize
																					}}
																				/>
																				<div className="form-controls-additional">
																					<button
																						className="btn btn-link p-0"
																						onClick={(e) =>
																							selectImage(
																								e,
																								keywordImageType.slug,
																								keyword.id
																							)}
																					>
																						<span>Select</span>
																					</button>
																					<span className="link-separator mx-1">
																						|
																					</span>
																					<FileUpload
																						onFileChange={handleFileUpload}
																						isLinkButton={true}
																						imageType={
																							keywordImageType.slug
																						}
																						keywordId={keyword.id}
																					/>
																					{imageUrl !== defaultImage && (
																						<Fragment>
																							<span className="link-separator mx-1">
																								|
																							</span>
																							<button
																								className="btn btn-link p-0"
																								onClick={(e) =>
																									removeKeywordImage(
																										e,
																										keywordImageType.slug,
																										keyword.id
																									)}
																							>
																								<span>Remove</span>
																							</button>
																						</Fragment>
																					)}
																				</div>
																			</div>
																		);
																	})}
															</div>
														</div>
													</div>
												))}
											</Fragment>
										)}
									</div>
								</div>
							</div>
						</div>

						<CampaignButtonsContainer
							campaign={campaign}
							onSave={handleSubmit}
							onSaveNext={() => handleSubmit(true)}
						/>

						{showSelectImageDialog && (
							<ModalDialog position="right-sidebar">
								<ModalDialogContent align="start">
									<h4 className="mb-3">Select {selectedCategory === 'image' ? 'Image' : 'Icon'}</h4>
									<form className="row">
										<div className="col-12 mb-2">
											<div className="search-images-wrapper input-group mb-3">
												<input
													type="search"
													className="form-control"
													name="searchTerm"
													value={searchTerm || ''}
													onChange={(e) => setSearchTerm(e.target.value)}
													onKeyDown={onImageSearchKeyDown}
													placeholder="Enter search term"
													disabled={isSettingPreview}
												/>
												<span className="input-group-append">
													<button
														type="button"
														className="btn btn-primary border btn-search-images"
														onClick={() =>
															selectedCategory === 'image' ? getImages() : getIcons(1)}
														disabled={isSettingPreview}
													>
														<i className="fa fa-search" />
													</button>
												</span>
											</div>
										</div>
									</form>

									<div
										className={`images-list ${selectedCategory === 'image'
											? ''
											: 'icons'} border text-center ${isSettingPreview ? 'disabled' : ''}`}
									>
										{isSearching ? (
											<Spinner />
										) : (
											<Fragment>
												{selectedCategory === 'image' ? (
													imagesList && (
														<Fragment>
															{imagesList.map((image) => (
																<a
																	className="image-card"
																	onClick={selectImageFromList}
																	key={image.Id}
																	id={`image${image.Id}`}
																>
																	<div className="image-preview text-center">
																		<img
																			src={image.ThumbLarge}
																			data-dpid={image.Id}
																			data-title={image.Title}
																			data-thumb={image.Thumb}
																			data-thumb-large={image.ThumbLarge}
																			data-thumb-max={image.ThumbMax}
																			data-url-big={image.UrlBig}
																		/>
																	</div>
																</a>
															))}
														</Fragment>
													)
												) : (
													<Fragment>
														{iconsList.map((icon) => (
															<a
																className="icon-card"
																onClick={selectImageFromList}
																key={icon.Id}
																id={`icon${icon.Id}`}
															>
																<div className="icon-card-wrapper">
																	<div className="icon-preview text-center">
																		<img
																			className="icon-image"
																			src={icon.PreviewUrl}
																		/>
																	</div>
																</div>
															</a>
														))}
														{iconsList &&
														iconsList.length >= 50 && (
															<div className="icons-list-view-more">
																<a className="btn btn-link" onClick={viewMoreIcons}>
																	View More
																</a>
															</div>
														)}
													</Fragment>
												)}
											</Fragment>
										)}
									</div>
								</ModalDialogContent>
								<ModalDialogButtons shaded align="start">
									<button className="btn btn-primary" onClick={applySelectedImage}>
										{isSettingPreview ? (
											<Fragment>
												<span className="spinner-border m-0 me-2" />
												<span>Applying</span>
											</Fragment>
										) : (
											<span>Select</span>
										)}
									</button>
									<button
										className="btn btn-outline-primary"
										onClick={toggleSelectImageDialog}
										disabled={isSettingPreview}
									>
										<span>Cancel</span>
									</button>
								</ModalDialogButtons>
							</ModalDialog>
						)}
					</Fragment>
				)}
			</Fragment>
		</div>
	);
};

export default CampaignDesignScreen;
