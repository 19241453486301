import { useStateValue } from '../../../StateProvider';
import { Fragment, useRef, useState, useEffect } from 'react';
import './AdministrationView.css';
import PageHeadline from '../../pageHeadline/PageHeadline';
import { GetProducts, GetUsers } from '../../../Services';
import UsersList from '../users/usersList/UsersList';
import Spinner from '../../spinner/Spinner';
import ProductsList from '../products/ProductsList';
import SettingsDialog from '../settingsDialog/SettingsDialog';

const AdministrationView = () => {
	const [ { user }, dispatch ] = useStateValue();
	const [ isLoadingUsers, setIsLoadingUsers ] = useState(true);
	const [ isLoadingProducts, setIsLoadingProducts ] = useState(true);
	const [ users, setUsers ] = useState([]);
	const [ allUsers, setAllUsers ] = useState([]);
	const [ allProducts, setAllProducts ] = useState([]);
	const [ showDefaultSettingsDialog, setShowDefaultSettingsDialog ] = useState(false);
	const searchListInputRef = useRef();

	useEffect(() => {
		getUsers();
		getProducts();
	}, []);

	const getUsers = async () => {
		setIsLoadingUsers(true);

		let response = await GetUsers();

		if (response && response.length) {
			//sort users alphabetically
			response = response.sort((a, b) => a.firstName.localeCompare(b.firstName));

			setAllUsers(response);
			setUsers(response);
		}

		setIsLoadingUsers(false);
	};

	const getProducts = async () => {
		setIsLoadingProducts(true);

		let response = await GetProducts();

		if (response && response.length) {
			//sort products alphabetically
			response = response.sort((a, b) => a.name.localeCompare(b.name));

			setAllProducts(response);
		}

		setIsLoadingProducts(false);
	};

	const onSearchChange = (e) => {
		if (searchListInputRef.current) {
			let tempAllUsers = [];

			if (e.target.value === '') {
				tempAllUsers = allUsers;

				searchListInputRef.current.classList.remove('has-value');
			} else {
				tempAllUsers = allUsers.filter(
					(x) =>
						(x.firstName &&
							x.firstName.toLowerCase().includes(searchListInputRef.current.value.toLowerCase())) ||
						(x.lastName &&
							x.lastName.toLowerCase().includes(searchListInputRef.current.value.toLowerCase())) ||
						(x.email && x.email.toLowerCase().includes(searchListInputRef.current.value.toLowerCase()))
				);

				searchListInputRef.current.classList.add('has-value');
			}

			setUsers(tempAllUsers);
		}
	};

	const handleToggleDefaultSettingsDialog = async () => {
		setShowDefaultSettingsDialog(!showDefaultSettingsDialog);
	};

	return (
		<Fragment>
			<div className="container-fluid p-3">
				<div className="row">
					<div className="col-12">
						<PageHeadline
							headline="Administration"
							linkText="Campaigns"
							linkUrl="/campaigns/list"
							showSearchBox={true}
							buttonsHtml={
								<Fragment>
									{user &&
									(user.isAdmin || user.isSalesUser) && (
										<div className="d-flex justify-content-end">
											<div className="search-list-wrapper align-self-start">
												<input
													type="search"
													className="form-control"
													name="searchListTerm"
													onChange={onSearchChange}
													placeholder="Search List"
													ref={searchListInputRef}
													tabIndex="0"
												/>
												<button type="button" className="btn btn-link btn-search px-2">
													<i className="fa fa-search me-0" />
												</button>
											</div>
											<button
												className="btn btn-primary align-self-start ms-2"
												type="button"
												onClick={handleToggleDefaultSettingsDialog}
											>
												Settings
											</button>
										</div>
									)}
								</Fragment>
							}
						/>
					</div>
				</div>

				<ul className="nav nav-tabs" id="tabAdministration" role="tablist">
					<li className="nav-item" role="presentation" key="users">
						<button
							className="nav-link active"
							id="users-tab"
							data-bs-toggle="tab"
							data-bs-target="#users-tab-pane"
							type="button"
							role="tab"
							aria-controls="users-tab-pane"
							aria-selected="true"
						>
							Users
						</button>
					</li>
					<li className="nav-item" role="presentation" key="products">
						<button
							className="nav-link"
							id="products-tab"
							data-bs-toggle="tab"
							data-bs-target="#products-tab-pane"
							type="button"
							role="tab"
							aria-controls="products-tab-pane"
							aria-selected="true"
						>
							Products
						</button>
					</li>
				</ul>
				<div className="tab-content border" id="tabAdministrationContent">
					<div
						className="tab-pane fade position-relative p-3 show active"
						id="users-tab-pane"
						role="tabpanel"
						aria-labelledby="users-tab"
						tabIndex="0"
					>
						{isLoadingUsers ? <Spinner /> : <UsersList allUsers={users} onSubmit={getUsers} />}
					</div>
					<div
						className="tab-pane fade position-relative p-3"
						id="products-tab-pane"
						role="tabpanel"
						aria-labelledby="products-tab"
						tabIndex="0"
					>
						{isLoadingProducts ? (
							<Spinner />
						) : (
							<ProductsList allProducts={allProducts} onSubmit={getProducts} />
						)}
					</div>
				</div>
			</div>

			{showDefaultSettingsDialog && <SettingsDialog onClose={handleToggleDefaultSettingsDialog} />}
		</Fragment>
	);
};

export default AdministrationView;
